@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap);
.y_sidelink{
    display: flex;
    justify-content: flex-start;
    margin-left: 3em;
}

.y_sidelink_text{
    font-weight: 500;
    font-size: 18px;
    margin-left: 15px;
    color: #828996;
}

.y_sidelink_text_active{
    font-weight: 600;
    font-size: 18px;
    margin-left: 15px;
    color: white;
}

.y_active{
    border-left: 3px solid white;
}


.full_height{
    height: 100vh;
    width:  20%;
    background-color: #00084F;
    overflow: auto;
    position: fixed;
}

.full_width{
    width: 100%;
    z-index: 999999999999px !important;
    position: relative;
}

.score_category{
    font-weight : 600;
    font-size: 13px;
}

.is-clickable{
    cursor: pointer;
}
.is-grey{
    color: grey;
}
.is-nav-link{
    color: #828996;
    font-weight: 500;
    font-size: 20px;
    margin-left: 25px;
    margin-right: 25px;
}

.is-active{
    color: #002E6D;
    font-weight: bold;
}

@media only screen and (min-width : 200px) and (max-width: 600px){
    .is-nav-link{
        display: none;
    }
    .nav-login{
        margin-left: 60px;
    }
}




.full_body{
    min-height: 100vh !important;
    min-width: 100vw !important;
}

.dt-button{
    border: 2px solid grey;
    padding: 8px;
    margin-left: 5px;
    font-weight: bold;
}

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
  }
  
  .grecaptcha-badge:hover {
    width: 256px !important;
  }
  

.form-table{
    border: 0px !important;
}



table.dataTable tbody tr {
    background-color: inherit !important;
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate  {
    color: inherit !important;
}




.dataTables_wrapper .dataTables_filter input, .dataTables_wrapper .dataTables_length select {
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 100px !important;
    border-color: #A2AAB6 !important;
    outline: none !important;
    font-size: 1em !important;
}


.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }
  .tags-cell{
    flex-wrap: wrap !important;
  }
